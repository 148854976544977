import React from 'react';
import Header from '../components/Header';
import Hero from '../components/HeroSection';
import Record from '../components/RecordSection';
import Features from '../components/FeaturesSection';
import About from '../components/AboutSection';
import Support from '../components/SupportSection';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Record />
      <Features />
      <About />
      <Support />
      <Footer />
    </div>
  );
};

export default Home;
