import React from 'react';

const XLogo = (props) => {
  return (
    <svg {...props} viewBox='0 0 24 21' xmlns='http://www.w3.org/2000/svg'>
      {' '}
      <g clipPath='url(#clip0_51_521)'>
        <path
          d='M18.88 0H22.56L14.48 8.91144L23.92 21H16.512L10.712 13.6539L4.07199 21H0.391987L8.95199 11.4686L-0.0880127 0H7.50399L12.744 6.7107L18.88 0ZM17.592 18.9077H19.632L6.43199 2.01476H4.23999L17.592 18.9077Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_51_521'>
          <rect width='24' height='21' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XLogo;
