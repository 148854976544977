import React from 'react';
import cn from 'classnames';
import Base from '../../assets/full-base-logo.svg';
import PinkSale from '../../assets/pinksale.png';
import Ethereum from '../../assets/ethereum-eth-logo-full-horizontal.png';
import UNCX from '../../assets/UNCX.png';
import GEMPAD from '../../assets/gempad-logo-1024x275.png';
import Flokifi from '../../assets/flokifi.png';
import Binance from '../../assets/binance.png';
import TF from '../../assets/Blue_TF_Logotype.png';
import styles from './Support.module.scss';

const Support = () => {
  return (
    <section className={styles.support}>
      <h2>Innovations Behind Every Feature</h2>

      <div className={styles.drawer}>
        <div className={styles.top}>
          <div className={cn(styles.box, styles.left)}>
            <img src={Base} alt='base-logo' className={styles.svg} />
          </div>
          <div className={cn(styles.box, styles.left)}>
            <img src={PinkSale} alt='pink-sale-logo' />
          </div>
          <div className={cn(styles.box, styles.left)}>
            <img src={Ethereum} alt='Ethereum-logo' />
          </div>
          <div className={cn(styles.box, styles.left)}>
            <img src={UNCX} alt='UNCX-logo' />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={cn(styles.box, styles.right)}>
            <img src={GEMPAD} alt='GemPad-logo' />
          </div>
          <div className={cn(styles.box, styles.right)}>
            <img src={Flokifi} alt='Flokifi-logo' />
          </div>
          <div className={cn(styles.box, styles.right)}>
            <img src={Binance} alt='binance-logo' />
          </div>
          <div className={cn(styles.box, styles.right)}>
            <img src={TF} alt='tf-logo' />
          </div>
        </div>

        <div className={styles['light-1']} />
      </div>
    </section>
  );
};

export default Support;
