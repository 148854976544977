import React from 'react';
import cn from 'classnames';
import miniOctopus from '../../assets/mini-octopus.png';
import Ethereum from '../../assets/Ethereum.png';

import styles from './About.module.scss';

const About = () => {
  return (
    <section className={styles.about}>
      <h2 className={styles.head}>Who Can Use Esquid</h2>
      <div className={styles.wrapper}>
        <div className={cn(styles.box, styles.first)}>
          <img className={styles.image} src={Ethereum} alt='Ethereum' />
          <h3 className={styles.title}>A Marketplace you Can Trust</h3>
          <p className={styles.description}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </div>
        <div className={cn(styles.box, styles.second)}>
          <img className={styles.image} src={miniOctopus} alt='mini-octopus' />
          <h3 className={styles.title}>A Marketplace you Can Trust</h3>
          <p className={styles.description}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </div>

        <div className={styles['light-1']} />
      </div>
    </section>
  );
};

export default About;
