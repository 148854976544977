/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Octopus from '../../assets/logo-footer.png';
import LeftFoot from '../../assets/left-foot.png';
import RightFoot from '../../assets/right-foot.png';

import styles from './Footer.module.scss';
import useWidth from '../../utils/useWidth';
import GitBook from '../../assets/gitbook.js';
import Telegram from '../../assets/telegram.js';
import XLogo from '../../assets/X_logo.js';
import MediumIcon from '../../assets/medium.js';

const Footer = () => {
  const isMobile = useWidth();
  return (
    <footer>
      <div className={styles.content}>
        <ul className={styles.links}>
          <li className={styles.item}>
            <a className={styles.link} href='#'>
              Terms & Conditions
            </a>
          </li>
          <li className={styles.item}>
            <a className={styles.link} href='#'>
              Referral Terms
            </a>
          </li>
        </ul>

        <div className={styles.logo}>
          <img src={Octopus} alt='logo' />

          <div className={styles.textMobile}>
            Quid features and services are not available to Restricted Persons and
            US Persons.
            {isMobile !== 'mobile' && <br />}
            Please see the Terms & Conditions for further information.
          </div>
        </div>

        <div className={styles['external-links']}>
          <a href='#'>
            <GitBook />
          </a>
          <a href='#'>
            <Telegram />
          </a>
          <a href='#'>
            <XLogo />
          </a>
          <a href='#'>
            <MediumIcon />
          </a>
        </div>
      </div>

      <div className={styles.text}>
        Quid features and services are not available to Restricted Persons and
        US Persons.
        {isMobile !== 'mobile' && <br />}
        Please see the Terms & Conditions for further information.
      </div>

      <img className={styles.left} src={LeftFoot} alt='foot' />
      <img className={styles.right} src={RightFoot} alt='foot' />

      <div className={styles.light} />
      <div className={styles.lightFill} />
    </footer>
  );
};

export default Footer;
