import React, { useEffect, useState } from 'react';
import Button from '../baseElements/Button';
import cn from 'classnames';
import Logo from '../../assets/logo-header.png';
import Menu from '../../assets/Menu.svg';
import Arrow from '../../assets/arrow-up.svg';
import Modal from '../Modal';
import GitBook from '../../assets/gitbook.js';
import MediumIcon from '../../assets/medium.js';
import XLogo from '../../assets/X_logo.js';
import Telegram from '../../assets/telegram.js';

import styles from './Header.module.scss';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={Logo} alt='logo' />
        </div>
        <nav className={styles.nav}>
          <ul className={styles.list}>
            <li className={styles.item}>Docs</li>
            <li className={styles.item}>Stats</li>
            <li className={cn(styles.item, {
              [styles.open]: isDropdownOpen,
            })} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span>Community</span>
              <img className={styles.arrow} src={Arrow} alt='arrow' />
              <ul className={styles.dropdown}>
                <li className={styles.link}>
                  <span>GitBook</span>
                  <GitBook />
                </li>
                <li className={styles.link}>
                  <span>Telegram</span>
                  <Telegram />
                </li>
                <li className={styles.link}>
                  <span>X</span>
                  <XLogo />
                </li>
                <li className={styles.link}>
                  <span>Medium</span>
                  <MediumIcon />
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <div className={styles.menu} onClick={openModal}>
          <img src={Menu} alt='menu' />
        </div>
        <Button className={styles.button}>Lunch Quid dApp</Button>
      </header>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles['modal-content']}>
          <div className={styles.logo}>
            <img src={Logo} alt='logo' />
          </div>
          <nav className={styles.nav}>
            <ul className={styles.list}>
              <li className={styles.item}>Docs</li>
              <li className={styles.item}>Stats</li>
              <li className={styles.item} onClick={toggleMenu}>
                <span>Community</span>
                <img
                  className={cn(styles.arrow, {
                    [styles['open-arrow']]: isOpen,
                  })}
                  src={Arrow}
                  alt='arrow'
                />

                <ul
                  className={cn(styles.dropdown, {
                    [styles['open-dropdown']]: isOpen,
                  })}
                >
                  <li className={styles.link}>
                    <span>GitBook</span>
                    <GitBook />
                  </li>
                  <li className={styles.link}>
                    <span>Telegram</span>
                    <Telegram />
                  </li>
                  <li className={styles.link}>
                    <span>X</span>
                    <XLogo />
                  </li>
                  <li className={styles.link}>
                    <span>Medium</span>
                    <MediumIcon />
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </Modal>
    </>
  );
};

export default Header;
