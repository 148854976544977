import React from 'react';
import useWidth from '../../utils/useWidth';

import styles from './Record.module.scss';

const Record = () => {
  const isMobile = useWidth();
  const length = isMobile === 'mobile' ? 6 : 9;

  const svnArr = Array.from({ length: length }, (_, index) => (
    <div className={styles.line} key={index}></div>
  ));
  return (
    <section className={styles.Record}>
      <div className={styles.content}>
        <h2 className={styles.title}>Aggregator Stats</h2>
        <div className={styles.wrapper}>
          <div className={styles.box}>
            <p>57%</p>
            <span>
              DeFi projects struggle with locked liquidity, limiting
              adaptability.
            </span>
          </div>
          <div className={styles.box}>
            <p>$4.2B</p>
            <span>locked liquidity</span>
          </div>
          <div className={styles.box}>
            <p>57%</p>
            <span>
              Lack of Flexibility: DeFi projects face liquidity issues due to
              slow adaptability.
            </span>
          </div>
        </div>
      </div>
      <div className={styles['line-wrapper']}>{svnArr}</div>
    </section>
  );
};

export default Record;
