import ReactDOM from 'react-dom';
import Close from '../../assets/add.svg';

import styles from './Modal.module.scss';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={styles['modal-overlay']} onClick={onClose}>
      <div
        className={styles['modal-content']}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={styles['modal-close']} onClick={onClose}>
          <img src={Close} alt='close' />
        </button>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};
export default Modal;
