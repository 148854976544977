import React from 'react';
import heroImage from '../../assets/hero-image.png';
import Button from '../baseElements/Button';

import styles from './Hero.module.scss';

const Hero = () => {
  return (
    <section className={styles.hero}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2 className={styles['content-title']}>
            Web3 Escrow For
            <br /> Locked
            <span className={styles['content-title-primary']}> Liquidity</span>
          </h2>
          <p className={styles['content-description']}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit consectetur.
          </p>
          <Button className={styles.button}>Start Trading</Button>
        </div>
      </div>
      
      <div className={styles.image}>
        <img src={heroImage} alt='hero-image' />
      </div>
    </section>
  );
};

export default Hero;
