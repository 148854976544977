import React from 'react';

const Telegram = (props) => {
  return (
    <svg {...props} viewBox='0 0 23 19' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.589761 8.70518C0.641313 8.67939 0.692888 8.65489 0.743151 8.63169C1.61695 8.22689 2.50235 7.84787 3.38646 7.46885C3.43415 7.46885 3.51403 7.41341 3.55914 7.39536C3.62744 7.36571 3.69576 7.33736 3.76407 7.30771C3.89552 7.25098 4.02699 7.19554 4.15715 7.13882C4.42007 7.02666 4.68167 6.91452 4.94459 6.80236C5.46912 6.57805 5.99366 6.35373 6.51819 6.12813C7.56727 5.6795 8.61766 5.22955 9.66673 4.78092C10.7158 4.33229 11.7662 3.88238 12.8152 3.43375C13.8643 2.98512 14.9147 2.5352 15.9637 2.08657C17.0128 1.63794 18.0632 1.18803 19.1123 0.739395C19.3455 0.638839 19.5981 0.489292 19.8482 0.44546C20.0582 0.408074 20.2631 0.335892 20.4745 0.295928C20.8753 0.219867 21.3174 0.188922 21.7014 0.355225C21.8342 0.413237 21.9566 0.494439 22.0584 0.596283C22.5456 1.07843 22.4773 1.87 22.3742 2.5481C21.6563 7.2742 20.9385 12.0016 20.2193 16.7277C20.1214 17.3761 19.9873 18.0877 19.4757 18.4977C19.0427 18.8445 18.4266 18.8831 17.8918 18.7362C17.3569 18.5879 16.8852 18.2772 16.4226 17.9717C14.5035 16.7006 12.5833 15.4295 10.6643 14.1583C10.208 13.8567 9.70025 13.4622 9.7054 12.9143C9.70798 12.5843 9.90515 12.2903 10.1062 12.0286C11.7739 9.85251 14.1801 8.3571 15.9702 6.28153C16.2228 5.98889 16.4213 5.46034 16.0746 5.29146C15.8684 5.1909 15.6312 5.32753 15.4431 5.45774C13.0768 7.10143 10.7119 8.74641 8.34572 10.3901C7.57374 10.9264 6.76438 11.4782 5.83387 11.6096C5.00131 11.7283 4.1636 11.4962 3.35811 11.259C2.68278 11.0605 2.00872 10.8568 1.33727 10.6466C0.980271 10.5358 0.611674 10.4159 0.335873 10.1645C0.0600723 9.91311 -0.0984275 9.49028 0.0678264 9.1551C0.172218 8.94496 0.374562 8.81218 0.587213 8.70389L0.589761 8.70518Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Telegram;
