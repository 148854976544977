const MediumIcon = (props) => {
  return (
    <svg {...props} viewBox='0 0 26 14' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_51_523)'>
        <path
          d='M7.33291 0C11.3828 0 14.6656 3.12109 14.6656 6.97089C14.6656 10.8207 11.3825 13.9415 7.33291 13.9415C3.28329 13.9415 0 10.8207 0 6.97089C0 3.12109 3.28304 0 7.33291 0ZM19.043 0.408337C21.068 0.408337 22.7095 3.3462 22.7095 6.97089H22.7097C22.7097 10.5946 21.0682 13.5334 19.0433 13.5334C17.0183 13.5334 15.3768 10.5946 15.3768 6.97089C15.3768 3.34714 17.0181 0.408337 19.043 0.408337ZM24.7105 1.09188C25.4225 1.09188 26 3.72395 26 6.97089C26 10.2169 25.4228 12.8499 24.7105 12.8499C23.9982 12.8499 23.4213 10.2176 23.4213 6.97089C23.4213 3.72419 23.9985 1.09188 24.7105 1.09188Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_51_523'>
          <rect width='26' height='14' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MediumIcon;
